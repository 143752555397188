import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tabs,
} from "antd";
import classNames from "classnames";
import { snakeCase } from "lodash-es";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { TSportReportItem } from "@/api";
import { OpenSportReportDetailsModal } from "@/events";
import { useViewModel } from "@/hooks/use-view-model";
import { useGlobalStore } from "@/stores";
import CurrencySelector from "@/ui/_common_/currency-selector";
import DateRangePicker from "@/ui/_common_/date-range-picker";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import MoneyFormatter from "@/ui/_common_/money-formatter";
import Page from "@/ui/_common_/page";
import Pagination from "@/ui/_common_/pagination";
import Selector from "@/ui/_common_/selector";

import { DetailsModal } from "./DetailsModal";
import { SportReportState } from "./SportReportState";

function BonusReport() {
  const globalStore = useGlobalStore();
  const state = useViewModel(() => new SportReportState(globalStore), {});
  const intl = useIntl();
  const { permissionsStore } = globalStore;

  const _ = intl.formatMessage;

  return (
    <Page title={intl.formatMessage({ defaultMessage: "Sport Bets History" })}>
      <DetailsModal />
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          {permissionsStore.has("SelectClient") && (
            <Col xs={24} md={8} lg={6} xl={4}>
              <Selector
                store={state.clientSelectorStore}
                placeholder={intl.formatMessage({
                  defaultMessage: "Select client",
                })}
              />
            </Col>
          )}
          {!!state.clientSelectorStore.selectedId && (
            <>
              {permissionsStore.has("SelectAgent") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.agentSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select an agent",
                    })}
                  />
                </Col>
              )}
              {permissionsStore.has("SelectSubagent") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.subagentSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select subagent",
                    })}
                  />
                </Col>
              )}
              {permissionsStore.has("SelectHall") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.hallSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select shop",
                    })}
                  />
                </Col>
              )}
              <Col xs={24} md={8} lg={6} xl={4}>
                <Selector
                  store={state.playerSelectorStore}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Select player",
                  })}
                />
              </Col>
              <Col xs={24} md={8} lg={6} xl={4}>
                <Selector
                  store={state.kioskSelectorStore}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Select kiosk",
                  })}
                />
              </Col>
            </>
          )}
        </Row>
      </Card>
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          {!state.userStore.hall && (
            <Col xs={24} md={8} lg={6} xl={4}>
              <CurrencySelector
                value={state.currency}
                onChange={state.setCurrency}
                style={{ width: "100%" }}
              />
            </Col>
          )}
          <Col>
            <DateRangePicker store={state} />
          </Col>
          <Col xs={12} md={6} lg={6} xl={3} style={{ marginLeft: "auto" }}>
            <Button
              style={{ width: "100%" }}
              type="primary"
              disabled={!state.clientSelectorStore.selectedId}
              onClick={state.apply}
            >
              {intl.formatMessage({ defaultMessage: "Apply" })}
            </Button>
          </Col>
        </Row>
      </Card>
      <Card size="small">
        <Tabs
          destroyInactiveTabPane={true}
          activeKey={state.tab}
          onChange={(value) => (state.tab = value)}
          items={[
            {
              key: "details",
              label: intl.formatMessage({ defaultMessage: "Details" }),
              children: (
                <Spin spinning={state.filterQuery.isPending}>
                  <Space direction="vertical">
                    <Row gutter={[12, 12]}>
                      <Col xs={24} md={8} lg={6} xl={4}>
                        <Select
                          style={{ width: "100%" }}
                          value={state.status}
                          onChange={state.setStatus}
                          allowClear
                          placeholder={intl.formatMessage({
                            defaultMessage: "Status",
                          })}
                          options={[
                            {
                              value: "outcome",
                              label: intl.formatMessage({
                                defaultMessage: "Outcome",
                              }),
                            },
                            {
                              value: "cancelled",
                              label: intl.formatMessage({
                                defaultMessage: "Cancelled",
                              }),
                            },
                            {
                              value: "created",
                              label: intl.formatMessage({
                                defaultMessage: "Created",
                              }),
                            },
                            {
                              value: "cashout",
                              label: intl.formatMessage({
                                defaultMessage: "Cashout",
                              }),
                            },
                          ]}
                        />
                      </Col>
                      <Col xs={24} md={8} lg={6} xl={4}>
                        <Select
                          style={{ width: "100%" }}
                          value={state.isPayed}
                          onChange={state.setIsPayed}
                          placeholder={intl.formatMessage({
                            defaultMessage: "Payout status",
                          })}
                          options={[
                            {
                              value: true,
                              label: intl.formatMessage({
                                defaultMessage: "Payout",
                              }),
                            },
                            {
                              value: false,
                              label: intl.formatMessage({
                                defaultMessage: "Not payout",
                              }),
                            },
                          ]}
                          allowClear
                        />
                      </Col>
                      <Col xs={24} md={8} lg={6} xl={4}>
                        <Select
                          style={{ width: "100%" }}
                          value={state.isOnlyBonus}
                          onChange={state.setIsOnlyBonus}
                          placeholder={intl.formatMessage({
                            defaultMessage: "Bonus",
                          })}
                          options={[
                            {
                              value: true,
                              label: intl.formatMessage({
                                defaultMessage: "With bonus",
                              }),
                            },
                            {
                              value: false,
                              label: intl.formatMessage({
                                defaultMessage: "Without bonus",
                              }),
                            },
                          ]}
                          allowClear
                        />
                      </Col>
                      <Col xs={24} md={8} lg={6} xl={4}>
                        <Select
                          style={{ width: "100%" }}
                          value={state.outcomeStatus}
                          onChange={state.setOutcomeStatus}
                          placeholder={intl.formatMessage({
                            defaultMessage: "Outcome status",
                          })}
                          options={[
                            {
                              value: "win",
                              label: intl.formatMessage({
                                defaultMessage: "Win",
                              }),
                            },
                            {
                              value: "loose",
                              label: intl.formatMessage({
                                defaultMessage: "Lose",
                              }),
                            },
                          ]}
                          allowClear
                        />
                      </Col>
                      <Col
                        xs={24}
                        md={8}
                        lg={6}
                        xl={4}
                        style={{ marginLeft: "auto" }}
                      >
                        <Input.Search
                          placeholder={intl.formatMessage({
                            defaultMessage: "Enter UID",
                          })}
                          allowClear
                          onSearch={(value) => state.setUid(value)}
                        />
                      </Col>
                    </Row>
                    <ErrorsFormatter queries={[state.filterQuery]} />
                    <Table
                      dataSource={state.filterQuery.items}
                      showHeader={!!state.filterQuery.items.length}
                      rowKey="id"
                      size="small"
                      bordered
                      pagination={false}
                      components={{
                        body: {
                          row: (props: any) => {
                            const item = props.children?.[0]?.props?.record;
                            const type = snakeCase(item?.type);
                            return (
                              <tr
                                {...props}
                                className={classNames(
                                  props.className,
                                  type === "out_reserved"
                                    ? "ant-table-row-color-gray"
                                    : "",
                                )}
                              />
                            );
                          },
                        },
                      }}
                    >
                      <Table.Column
                        title={intl.formatMessage({
                          defaultMessage: "Date/time",
                        })}
                        dataIndex="createdAt"
                        render={(_, item: TSportReportItem) =>
                          new Date(item.createdAt).toLocaleString()
                        }
                      />
                      <Table.Column
                        title={intl.formatMessage({
                          defaultMessage: "Provider",
                        })}
                        dataIndex="gameProviderName"
                        render={(gameProviderName) => gameProviderName ?? "—"}
                      />
                      <Table.Column
                        title={intl.formatMessage({ defaultMessage: "UID" })}
                        dataIndex="uid"
                        render={(uid) => uid ?? "—"}
                      />
                      <Table.Column
                        title={intl.formatMessage({ defaultMessage: "Agent" })}
                        dataIndex="agentName"
                        render={(agentName) => agentName ?? "—"}
                      />
                      <Table.Column
                        title={intl.formatMessage({ defaultMessage: "Shop" })}
                        dataIndex="hallName"
                        render={(hallName) => hallName ?? "—"}
                      />
                      <Table.Column
                        title={intl.formatMessage({ defaultMessage: "Player" })}
                        dataIndex="playerName"
                        render={(playerName) => playerName ?? "—"}
                      />
                      <Table.Column
                        title={intl.formatMessage({ defaultMessage: "Kiosk" })}
                        dataIndex="kioskName"
                        render={(kioskName) => kioskName ?? "—"}
                      />
                      <Table.Column
                        title={intl.formatMessage({ defaultMessage: "Odds" })}
                        dataIndex="odds"
                      />
                      <Table.Column
                        title={intl.formatMessage({ defaultMessage: "Type" })}
                        dataIndex="type"
                        render={(type: string, item: TSportReportItem) =>
                          type === "multiple"
                            ? item.description
                            : intl.formatMessage({ defaultMessage: "Single" })
                        }
                      />
                      <Table.Column
                        title={intl.formatMessage({ defaultMessage: "Bet" })}
                        dataIndex="bet"
                        render={(value: number) => (
                          <MoneyFormatter cents={value} />
                        )}
                      />
                      <Table.Column
                        title={intl.formatMessage({ defaultMessage: "Win" })}
                        dataIndex="win"
                        render={(value: number, item: TSportReportItem) => {
                          const win = <MoneyFormatter cents={value} />;
                          const bonus =
                            item.bonusAmount > 0 ? (
                              <>
                                {" + "}
                                <MoneyFormatter cents={item.bonusAmount} />
                              </>
                            ) : null;
                          return (
                            <>
                              {win}
                              {bonus}
                            </>
                          );
                        }}
                      />
                      <Table.Column
                        title={intl.formatMessage({
                          defaultMessage: "Currency",
                        })}
                        dataIndex="currency"
                        render={(value: string) => value ?? "—"}
                      />
                      <Table.Column
                        title={intl.formatMessage({ defaultMessage: "Status" })}
                        dataIndex="status"
                        render={(value: string) =>
                          value
                            ? _({
                                id: `reports/sports/status/${value}`,
                                defaultMessage: value,
                              })
                            : "—"
                        }
                      />
                      <Table.Column
                        title={intl.formatMessage({ defaultMessage: "Payout" })}
                        dataIndex="isPayed"
                        render={(value: boolean) =>
                          value
                            ? intl.formatMessage({ defaultMessage: "Yes" })
                            : intl.formatMessage({ defaultMessage: "No" })
                        }
                      />
                      <Table.Column
                        title={intl.formatMessage({
                          defaultMessage: "Outcome Status",
                        })}
                        dataIndex="outcomeStatus"
                        render={(value: string) =>
                          value
                            ? _({
                                id: `reports/sports/outcome-status/${value}`,
                                defaultMessage: value,
                              })
                            : "—"
                        }
                      />
                      <Table.Column
                        title={intl.formatMessage({
                          defaultMessage: "Actions",
                        })}
                        render={(_, item: TSportReportItem) => (
                          <Button
                            size="small"
                            onClick={() =>
                              globalStore.eventBusService.publish(
                                new OpenSportReportDetailsModal({ item }),
                              )
                            }
                          >
                            {intl.formatMessage({ defaultMessage: "Details" })}
                          </Button>
                        )}
                      />
                    </Table>
                    <Pagination
                      query={state.filterQuery}
                      onChange={() => {
                        state.filter({ preservePageNumber: true });
                      }}
                    />
                  </Space>
                </Spin>
              ),
            },
            {
              key: "currency",
              label: intl.formatMessage({ defaultMessage: "Currency" }),
              children: (
                <Spin spinning={state.aggregateByCurrencyQuery.isPending}>
                  <Space direction="vertical">
                    <ErrorsFormatter
                      queries={[state.aggregateByCurrencyQuery]}
                    />
                    <Table
                      dataSource={state.aggregateByCurrencyQuery.items}
                      showHeader={!!state.aggregateByCurrencyQuery.items.length}
                      rowKey={(item) =>
                        state.aggregateByCurrencyQuery.items.indexOf(item)
                      }
                      size="small"
                      bordered
                      pagination={false}
                      columns={[
                        {
                          dataIndex: "currency",
                          title: intl.formatMessage({
                            defaultMessage: "Currency",
                          }),
                        },
                        {
                          title: intl.formatMessage({
                            defaultMessage: "Shops count",
                          }),
                          dataIndex: "hallsCount",
                        },
                        {
                          title: intl.formatMessage({
                            defaultMessage: "Players count",
                          }),
                          dataIndex: "playersCount",
                        },
                        {
                          title: intl.formatMessage({ defaultMessage: "Bet" }),
                          dataIndex: "betSum",
                          render: (value: number) => (
                            <MoneyFormatter cents={value} />
                          ),
                        },
                        {
                          title: intl.formatMessage({ defaultMessage: "Win" }),
                          dataIndex: "winSum",
                          render: (value: number) => (
                            <MoneyFormatter cents={value} />
                          ),
                        },
                        {
                          title: intl.formatMessage({
                            defaultMessage: "Profit",
                          }),
                          dataIndex: "profit",
                          render: (value: number) => (
                            <MoneyFormatter cents={value} />
                          ),
                        },
                        {
                          title: intl.formatMessage({
                            defaultMessage: "Payout amount",
                          }),
                          dataIndex: "payoutSum",
                          render: (value: number) => (
                            <MoneyFormatter cents={value} />
                          ),
                        },
                        {
                          title: intl.formatMessage({
                            defaultMessage: "Payout count",
                          }),
                          dataIndex: "payoutCount",
                        },
                        {
                          title: intl.formatMessage({
                            defaultMessage: "Coupons count",
                          }),
                          dataIndex: "couponsCount",
                        },
                        {
                          title: intl.formatMessage({
                            defaultMessage: "Cashout count",
                          }),
                          dataIndex: "cashoutCount",
                        },
                        {
                          title: intl.formatMessage({
                            defaultMessage: "Max bet",
                          }),
                          dataIndex: "maxBet",
                          render: (value: number) => (
                            <MoneyFormatter cents={value} />
                          ),
                        },
                        {
                          title: intl.formatMessage({
                            defaultMessage: "Min bet",
                          }),
                          dataIndex: "minBet",
                          render: (value: number) => (
                            <MoneyFormatter cents={value} />
                          ),
                        },
                        {
                          title: intl.formatMessage({
                            defaultMessage: "Max win",
                          }),
                          dataIndex: "maxWin",
                          render: (value: number) => (
                            <MoneyFormatter cents={value} />
                          ),
                        },
                      ]}
                    />
                    <Pagination
                      query={state.aggregateByCurrencyQuery}
                      onChange={() => {
                        state.aggregateByCurrency({ preservePageNumber: true });
                      }}
                    />
                  </Space>
                </Spin>
              ),
            },
            {
              key: "hall",
              label: intl.formatMessage({ defaultMessage: "Shop" }),
              children: (
                <Spin spinning={state.aggregateByHallQuery.isPending}>
                  <Space direction="vertical">
                    <ErrorsFormatter queries={[state.aggregateByHallQuery]} />
                    <Table
                      dataSource={state.aggregateByHallQuery.items}
                      showHeader={!!state.aggregateByHallQuery.items.length}
                      rowKey={(item) =>
                        state.aggregateByHallQuery.items.indexOf(item)
                      }
                      size="small"
                      bordered
                      pagination={false}
                      columns={[
                        {
                          dataIndex: "hallName",
                          title: intl.formatMessage({ defaultMessage: "Shop" }),
                        },
                        {
                          dataIndex: "currency",
                          title: intl.formatMessage({
                            defaultMessage: "Currency",
                          }),
                        },
                        {
                          title: intl.formatMessage({
                            defaultMessage: "Players count",
                          }),
                          dataIndex: "playersCount",
                        },
                        {
                          title: intl.formatMessage({ defaultMessage: "Bet" }),
                          dataIndex: "betSum",
                          render: (value: number) => (
                            <MoneyFormatter cents={value} />
                          ),
                        },
                        {
                          title: intl.formatMessage({ defaultMessage: "Win" }),
                          dataIndex: "winSum",
                          render: (value: number) => (
                            <MoneyFormatter cents={value} />
                          ),
                        },
                        {
                          title: intl.formatMessage({
                            defaultMessage: "Profit",
                          }),
                          dataIndex: "profit",
                          render: (value: number) => (
                            <MoneyFormatter cents={value} />
                          ),
                        },
                        {
                          title: intl.formatMessage({
                            defaultMessage: "Payout amount",
                          }),
                          dataIndex: "payoutSum",
                          render: (value: number) => (
                            <MoneyFormatter cents={value} />
                          ),
                        },
                        {
                          title: intl.formatMessage({
                            defaultMessage: "Payout count",
                          }),
                          dataIndex: "payoutCount",
                        },
                        {
                          title: intl.formatMessage({
                            defaultMessage: "Coupons count",
                          }),
                          dataIndex: "couponsCount",
                        },
                        {
                          title: intl.formatMessage({
                            defaultMessage: "Cashout count",
                          }),
                          dataIndex: "cashoutCount",
                        },
                        {
                          title: intl.formatMessage({
                            defaultMessage: "Max bet",
                          }),
                          dataIndex: "maxBet",
                          render: (value: number) => (
                            <MoneyFormatter cents={value} />
                          ),
                        },
                        {
                          title: intl.formatMessage({
                            defaultMessage: "Min bet",
                          }),
                          dataIndex: "minBet",
                          render: (value: number) => (
                            <MoneyFormatter cents={value} />
                          ),
                        },
                        {
                          title: intl.formatMessage({
                            defaultMessage: "Max win",
                          }),
                          dataIndex: "maxWin",
                          render: (value: number) => (
                            <MoneyFormatter cents={value} />
                          ),
                        },
                      ]}
                    />
                    <Pagination
                      query={state.aggregateByHallQuery}
                      onChange={() => {
                        state.aggregateByHall({ preservePageNumber: true });
                      }}
                    />
                  </Space>
                </Spin>
              ),
            },
            {
              key: "player",
              label: intl.formatMessage({ defaultMessage: "Player" }),
              children: (
                <Spin spinning={state.aggregateByPlayerQuery.isPending}>
                  <Space direction="vertical">
                    <ErrorsFormatter queries={[state.aggregateByPlayerQuery]} />
                    <Table
                      dataSource={state.aggregateByPlayerQuery.items}
                      showHeader={!!state.aggregateByPlayerQuery.items.length}
                      rowKey={(item) =>
                        state.aggregateByPlayerQuery.items.indexOf(item)
                      }
                      size="small"
                      bordered
                      pagination={false}
                      columns={[
                        {
                          dataIndex: "playerName",
                          title: intl.formatMessage({
                            defaultMessage: "Player",
                          }),
                        },
                        {
                          dataIndex: "hallName",
                          title: intl.formatMessage({ defaultMessage: "Shop" }),
                        },
                        {
                          dataIndex: "currency",
                          title: intl.formatMessage({
                            defaultMessage: "Currency",
                          }),
                        },
                        {
                          title: intl.formatMessage({ defaultMessage: "Bet" }),
                          dataIndex: "betSum",
                          render: (value: number) => (
                            <MoneyFormatter cents={value} />
                          ),
                        },
                        {
                          title: intl.formatMessage({ defaultMessage: "Win" }),
                          dataIndex: "winSum",
                          render: (value: number) => (
                            <MoneyFormatter cents={value} />
                          ),
                        },
                        {
                          title: intl.formatMessage({
                            defaultMessage: "Profit",
                          }),
                          dataIndex: "profit",
                          render: (value: number) => (
                            <MoneyFormatter cents={value} />
                          ),
                        },
                        {
                          title: intl.formatMessage({
                            defaultMessage: "Payout amount",
                          }),
                          dataIndex: "payoutSum",
                          render: (value: number) => (
                            <MoneyFormatter cents={value} />
                          ),
                        },
                        {
                          title: intl.formatMessage({
                            defaultMessage: "Payout count",
                          }),
                          dataIndex: "payoutCount",
                        },
                        {
                          title: intl.formatMessage({
                            defaultMessage: "Coupons count",
                          }),
                          dataIndex: "couponsCount",
                        },
                        {
                          title: intl.formatMessage({
                            defaultMessage: "Cashout count",
                          }),
                          dataIndex: "cashoutCount",
                        },
                        {
                          title: intl.formatMessage({
                            defaultMessage: "Max bet",
                          }),
                          dataIndex: "maxBet",
                          render: (value: number) => (
                            <MoneyFormatter cents={value} />
                          ),
                        },
                        {
                          title: intl.formatMessage({
                            defaultMessage: "Min bet",
                          }),
                          dataIndex: "minBet",
                          render: (value: number) => (
                            <MoneyFormatter cents={value} />
                          ),
                        },
                        {
                          title: intl.formatMessage({
                            defaultMessage: "Max win",
                          }),
                          dataIndex: "maxWin",
                          render: (value: number) => (
                            <MoneyFormatter cents={value} />
                          ),
                        },
                      ]}
                    />
                    <Pagination
                      query={state.aggregateByPlayerQuery}
                      onChange={() => {
                        state.aggregateByPlayer({ preservePageNumber: true });
                      }}
                    />
                  </Space>
                </Spin>
              ),
            },
          ]}
        />
      </Card>
    </Page>
  );
}

export default observer(BonusReport);
